import react, { useEffect, useState } from "react";
import { Button, Collapse, Container } from "react-bootstrap";
import { screeningsClient } from "../Client/ScreeningsClient";

import { useUrlSearchParams } from "use-url-search-params";

export interface ITest {
  companyName: string;
  isCompleted: boolean;
}

export const Main = () => {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState<boolean>(false);
  const [invisable, setInvisable] = useState<boolean>(false);
  const [user, setUser] = useState<ITest | undefined>();
  const [query, setQuery] = useUrlSearchParams(
    {},
    {
      token: String,
    }
  );

  useEffect(() => {
    getUsers();
    identityVerificationCheck();
  }, []);

  useEffect(() => {
    if (!query.token || query.token === "") {
      closeTab();
    }
  }, [query]);

  const theme = () => {
    return {
      logo: "https://cdn.globalpass.ch/assets/logo_gp.svg",
      name: "GlobalPass",
      class: "primary",
    };
  };

  const closeTab = () => {
    window.close();
  };

  const identityVerificationCheck = () => {
    if (user?.isCompleted) {
      closeTab();
    } else {
      return;
    }
  };

  const getUsers = async () => {
    const response = await screeningsClient.getUserData(query.token as string);
    setUser(response.data);
    setLoading(false);

    if (response.status === 200) initWIdget();
  };

  const initWIdget = () => {
    const script = document.createElement("script");
    script.src = String(process.env.REACT_APP_WIDGET_URL);
    script.async = true;
    script.onload = () => {
      (window as any).GPScreeningWidget.init({
        elementId: "widget",
        token: query.token as string,
        redirectUri: null,
      });
    };

    document.body.appendChild(script);
  };

  const handleIdentityVerificationOnClick = () => {
    setOpen(!open);
    setInvisable(true);
  };

  return loading ? (
    <></>
  ) : (
    <Container>
      <div className="row mt-5">
        <div className="offset-lg-2 col-lg-8 col-md">
          <div className={`${invisable ? "d-none" : ""}`}>
            <div className="d-flex justify-content-center">
              <img src={theme().logo} alt="Logo" />
            </div>
            <div className="mt-5">
              <h5>{`You have been invited to verify your identity in the ${
                theme().name
              } platform by ${user?.companyName}`}</h5>
            </div>
            <div className="d-flex justify-content-center">
              <Button
                onClick={handleIdentityVerificationOnClick}
                aria-controls="widget-collapse"
                aria-expanded={open}
                className="mt-5 float-center"
                variant={theme().class}
              >
                Identity Verification
              </Button>
            </div>
            <div>
              <p className="lead mt-5">Why it is needed ?</p>
            </div>
            <div className="text-justify mt-0">
              Identity verification ensures that there is a real person behind a
              process and proves that the one is who he or she claims to be,
              preventing both a person from carrying out a process on behalf
              without authorization, and creating false identities or commit
              fraud.
            </div>
          </div>
          <Collapse in={open} timeout={0} className="mt-0">
            <div id="widget-collapse">
              <div id="widget"></div>
            </div>
          </Collapse>
        </div>
      </div>
    </Container>
  );
};
